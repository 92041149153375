// Other stuff
import { activateFocus } from './lib/focus.js'
import { activateTracking } from './lib/tracking.js'
import AOS from 'aos'

/*
 * Activate Utilities
 */
activateFocus()
AOS.init()
activateTracking()

/*
 * Mobile Navigation
 */

// Hamburger Menu
const el = document.querySelector('.hamburger')
const nav = document.querySelector('header')

if (el) {
    el.addEventListener('click', () => {
        nav.classList.toggle('-is-open')
    })
}

// Close mobile nav on scroll down
let scrollPos = 0;

function checkPosition() {
    let windowY = window.scrollY;

    if (windowY > scrollPos) {
        // Scrolling DOWN
        nav.classList.remove('-is-open');
    }
    scrollPos = windowY;
}

function debounce(func, wait = 10, immediate = true) {
    let timeout
    return function () {
        let context = this,
            args = arguments
        let later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        let callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

window.addEventListener('scroll', debounce(checkPosition))
