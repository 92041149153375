import vars from './vars.js';

// We rely on loud and non-pretty focus states to facilitate easy keyboard navigation.
// These are frequently triggered by mouse users as well
// This utility adds an attribute to elements when they are *clicked* so that we can control if we want to allow a focus state to appear for them in that case.

const query = 'a, button, input, select, textarea, [tabindex]';

var suppressedElements = [];

function suppressFocus(el) {
  el.style.outline = 'none';
  el.dataset.focusSupressed = true;
  suppressedElements.push(el);

  // If this element has a parent, look for focusable parents and also supress their focus states
  if (el.parentElement) {
    let parentControl = el.parentElement.closest(query);

    parentControl && suppressFocus(parentControl);
  }
}

function activateFocus() {
  document.addEventListener('mousedown', (ev) => {
    vars.lastUserAction = 'click';

    var control = ev.target.closest(query);

    // If a label was clicked, consider the control to be its input
    if (!control && ev.target.closest('label')) {
      control = document.getElementById(ev.target.closest('label').getAttribute('for'));
    }

    if (control) {
      suppressFocus(control);
    }
  });

  document.addEventListener('keydown', () => {
    vars.lastUserAction = 'key';

    for (let i = 0; i < suppressedElements.length; i++) {
      let el = suppressedElements[i];

      el.style.outline = null;
      delete el.dataset.focusSupressed;
    }

    suppressedElements = []; // Nuke the array as no data need be saved
  });
}

// Export the functions so this file can be used as a JS module rather than a normal JS file.
export {activateFocus, suppressFocus};
