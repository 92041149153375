// Tracking functions
// -------------------------------
// Everything needed for tracking events via GTM
// utilises the data-tracking attribute on click interactions


// Get url Location
function getLocationObject(url) {
    var a = document.createElement('a');

    a.href = url;

    return {
      href: a.href,
      host: a.host,
      hostname: a.hostname,
      port: a.port,
      pathname: a.pathname,
      protocol: a.protocol,
      hash: a.hash,
      search: a.search
    };
  }

// Add event listener
function activateTracking() {
    window.addEventListener('click', (ev) => {
      var clicked = ev.target.closest(['a', 'button']);

      // If the element has tracking information...
      if (clicked && clicked.dataset.tracking) {
        // Buttons are easy. Just log the event and presume that whatever else they are supposed to do is happening.
        if (clicked.nodeName === 'BUTTON') {
          // CTCT form buttons have additional third-party script that interferes with our default tracking.
          // This causes a double-firing of the click event - one is the 'trusted' user click, the other is synthetic.
          // This artificially doubles conversion events for CTCT button clicks.
          // Resolve with logic to only track the 'trusted' user click event.
          let ctctForm = clicked.closest('form.ctct-form-custom');

          if (ctctForm) {
            if (ev.isTrusted) {
              dataLayer.push(JSON.parse(clicked.dataset.tracking));
              return;
            }
            return;
          }

          dataLayer.push(JSON.parse(clicked.dataset.tracking));
        } else if (clicked.nodeName === 'A') {
          // Don't handle any tracking if the the link points to the current location
          if (clicked.href === window.location.href) {
            return;
          }

          // If link has tracking data, track the click. Otherwise, ignore.
          let metaKeyDown = ev.metaKey;

          ev.preventDefault(); // Delay the click for now.

          try {
            handleTracking(clicked, metaKeyDown);
          } catch (err) {
            goToLink(clicked);
          }
        }
      }
    }, true);
  }

  function handleTracking(link, ev) {
    let eventParams = JSON.parse(link.dataset.tracking),
      successful;

    // Add a callback to the params we will push to GTM
    eventParams.eventCallback = () => {
      goToLink(link);

      successful = true;
    };

    // Log event to GTM. Once done, go to the link
    dataLayer.push(eventParams);

    // Set backup timeout in case it doesn’t work
    setTimeout(() => {
      if (!successful) {
        goToLink(link, ev);
      }
    }, 500);
  }

  function goToLink(link, metaKeyDown) {
    let location = getLocationObject(link.href);

    if (link.target || metaKeyDown) {
      window.open(link.href, link.target);
    } else {
      window.location.href = link.href;
    }
  }

  export {activateTracking};
