window.vars = {
  lastUserAction: null,
  keyCodes: { // Aliased so that individual components don‘t each have to check for this
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    delete: 46,
    enter: 13,
    space: 32,
    esc: 27,
    tab: 9,
    pageUp: 33,
    pageDown: 34
  }
};

export default window.vars;
